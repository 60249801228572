<template>
  <template v-if="presets.length > 0">
    <Popover
      v-slot="{ close }"
      class="relative"
    >
      <PopoverButton
        :class="buttonClass"
      >
        {{ t('labels.preset', 2) }}
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel class="absolute z-20 select-none text-black sm:text-sm right-0">
          <div class="text-left min-w-full shadow-lg bg-white border border-gray-100 ring-1 ring-black ring-opacity-5 text-xs overflow-auto rounded-md max-h-64 max-w-sm">
            <table>
              <tr class="sticky top-0 bg-white shadow-sm">
                <th class="p-2 font-medium">
                  {{ t('labels.name') }}
                </th>
                <th class="p-2 font-medium">
                  {{ t('labels.formula') }}
                </th>
              </tr>
              <tr
                v-for="preset in presets"
                :key="preset.name"
                class="cursor-pointer hover:bg-gray-200"
                @click="$emit('submit', preset); close()"
              >
                <td class="p-2">
                  {{ preset.name }}
                </td>
                <td class="p-2 font-mono text-gray-600">
                  {{ preset.reducer.params.formula }}
                </td>
              </tr>
            </table>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </template>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { DataDefinition } from '@/plugins/dashboard/definition'

import { METRIC_PRESETS } from './presets'

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel
  },
  props: {
    buttonClass: {
      type: String as PropType<object | string | string[] | undefined>,
      default: undefined
    },
    definition: {
      type: Object as PropType<DataDefinition>,
      required: true
    }
  },
  emits: ['submit'],
  setup (props) {
    const { t } = useI18n()
    const presets = computed(() => {
      return METRIC_PRESETS.filter(p => p.isAvailable(props.definition)).map(p => p.apply(props.definition))
    })

    return {
      presets,
      t
    }
  }
})
</script>
