import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function blocklists (): RouteRecordRaw[] {
  const context = ContextType.PUBLISHERS
  return [
    {
      path: 'blocklists',
      name: `${context}.blocklists.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.blocklist', 100),
        feature: 'blocklists',
        roles: rolesGroup.user
      },
      children: [
        {
          path: 'create',
          name: `${context}.blocklists.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('blocklists.create'),
            roles: rolesGroup.operation,
            feature: 'blocklists'
          }
        },
        {
          path: ':blocklistId/edit',
          name: `${context}.blocklists.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('blocklists.edit', { name: ':blocklistName' }),
            pattern: ':blocklistName',
            feature: 'blocklists'
          },
          props: route => ({ blocklistId: route.params.blocklistId })
        }
      ]
    }
  ]
}
