<template>
  <button
    :title="t('labels.information')"
    :class="buttonClass"
    @click="open = true"
  >
    <InformationCircleIcon class="h-4 w-4" />
  </button>
  <Modal
    v-model:open="open"
    css="overflow-hidden"
  >
    <template #title>
      {{ t('dashboards.widgetInfoTitle') }}
    </template>

    <table class="w-full text-sm">
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.uid') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          {{ definition.uid }}
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.store') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          {{ definition.store.name }}
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.dataDimensions') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          {{ definition.dimensions.map(d => translateDBName(d.name)).join(', ') }}
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.requiredFilters') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          {{ definition.store.requiredFilters.map(f => `${translateDBName(f.dimension)} (${f.min} ≤ n ≤ ${f.max})`).join(', ') }}
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.refreshDimensions') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          {{ definition.store.refreshDimensions.map(d => translateDBName(d)).join(', ') }}
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.periodOverride') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          <template v-if="definition.store.periodOverride !== undefined">
            <span v-if="typeof definition.store.periodOverride === 'string'">
              {{ PRESET_TRANSLATIONS[definition.store.periodOverride] }}
            </span>
            <div
              v-else-if="typeof definition.store.periodOverride === 'object'"
              class="ml-2.5 max-h-full flex flex-row items-center"
            >
              <span>{{ format(definition.store.periodOverride.start, 'yyyy-MM-dd') }}</span>
              <template v-if="startOfDay(definition.store.periodOverride.start).getTime() !== startOfDay(definition.store.periodOverride.end).getTime()">
                <span>
                  <ArrowLongRightIcon
                    class="w-4 h-4 inline-block mx-0.5 align-text-bottom"
                    aria-hidden="true"
                  />
                </span>
                <span>{{ format(definition.store.periodOverride.end, 'yyyy-MM-dd') }}</span>
              </template>
            </div>
          </template>
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.comparisonPeriodOverride') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          <span v-if="definition.store.comparisonPeriodOverride !== undefined">
            {{ PRESET_TRANSLATIONS[definition.store.comparisonPeriodOverride] }}
          </span>
        </td>
      </tr>
      <tr class="odd:bg-gray-200">
        <td class="p-1 pl-3">
          {{ t('labels.comparisonDisabled') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          {{ definition.disableComparison === true ? t('labels.yes') : t('labels.no') }}
        </td>
      </tr>
      <tr
        v-if="definition.vizType === 'table'"
        class="odd:bg-gray-200"
      >
        <td class="p-1 pl-3">
          {{ t('dashboards.sortDefault') }}
        </td>
        <td class="p-1 pr-3 text-gray-600">
          <template v-if="definition.defaultSort">
            {{ translateDBName(definition.defaultSort.column) }} ({{ t('labels.'+definition.defaultSort.order.toLowerCase()) }})
          </template>
          <template v-else>
            {{ t('dashboards.dontSort') }}
          </template>
        </td>
      </tr>
    </table>
  </Modal>
</template>

<script lang="ts">
import { InformationCircleIcon, ArrowLongRightIcon } from '@heroicons/vue/24/outline'
import { format, startOfDay } from 'date-fns'
import { defineComponent, ref, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { WidgetDefinition, translateDBName } from '@/plugins/dashboard'

import { PRESET_TRANSLATIONS } from '@/components/DateRangePicker/translations'
import Modal from '@/components/Modal/Modal.vue'

export default defineComponent({
  components: {
    Modal,
    InformationCircleIcon,
    ArrowLongRightIcon
  },
  props: {
    definition: {
      type: Object as PropType<WidgetDefinition>,
      required: true
    },
    buttonClass: {
      type: String as PropType<object | string | string[] | undefined>,
      default: undefined
    }
  },
  setup () {
    const { t } = useI18n()
    const open = ref(false)
    return {
      open,
      translateDBName,
      format,
      startOfDay,
      PRESET_TRANSLATIONS,
      t
    }
  }
})
</script>
