<template>
  <div
    :class="[
      'bg-white py-2.5 rounded-md grid max-h-full grid-rows-[auto,1fr]',
      {
        'px-4': !noPadding,
        'h-full': isDefaultOpen
      }
    ]"
  >
    <Disclosure
      v-if="mode === 'disclosure'"
      v-slot="{ open }"
      :default-open="isDefaultOpen"
    >
      <div
        class="flex items-center h-8 space-x-4"
      >
        <DisclosureButton
          ref="disclosureButtonRef"
          class="flex items-center space-x-1.5"
        >
          <ChevronRightIcon
            class="w-5 h-5 transition-transform text-text-primary"
            :class="open ? 'transform rotate-90' : ''"
            aria-hidden="true"
          />
          <span class="font-semibold text-text-primary leading-5.5">{{ title }}</span>
          <span
            v-if="(selectedRows?.length || 0) > 0"
            class="self-end text-sm font-light text-gray-700"
          >
            {{ t('labels.selectedRows', selectedRows!.length) }}
          </span>
          <button
            v-if="sectionClick"
            type="button"
            class="p-2"
            @click="sectionClick(sectionId)"
          >
            <PencilSquareIcon class="w-4 h-4 text-text-primary" />
          </button>
        </DisclosureButton>

        <div
          v-if="open"
          class="flex space-x-2 items-center justify-center !ml-auto"
        >
          <ViewsPanel
            v-if="isServerFilters"
            v-model:filters="filtersController"
          />

          <BulkEditButton
            v-if="canBulkUpdate"
            :disabled="selectedRows.length <= 0"
            @click="onClickBulkEdit"
          />

          <FiltersPanel
            v-if="isServerFilters && availableFilters.length"
            v-model:filters="filtersController"
            :available-filters="availableFilters"
          />

          <PropertiesPanel v-model:columns="dtColumns" />

          <SortsPanel
            v-model:filters="filtersController"
            :columns="columns"
          />

          <SearchPanel
            v-if="isServerFilters"
            v-model:filters="filtersController"
          />
        </div>
        <slot
          name="rightAction"
          :button-ref="disclosureButtonRef"
          :open="open"
        />
      </div>
      <DisclosurePanel
        class="flex flex-col mt-2.5 space-y-1.5 overflow-hidden"
      >
        <slot name="information" />
        <slot />
      </DisclosurePanel>
    </Disclosure>
    <template v-else>
      <div
        class="flex flex-col gap-y-4"
        :class="{'px-4': noPadding}"
      >
        <div class="flex justify-between">
          <div class="flex items-center gap-x-2">
            <div class="flex flex-col items-start">
              <div class="flex flex-row items-end gap-2">
                <span
                  v-if="title"
                  class="font-semibold text-text-primary leading-5.5"
                >{{ title }}</span>
                <span
                  v-if="(selectedRows?.length || 0) > 0"
                  class="self-end text-sm font-light text-gray-700"
                >
                  {{ t('labels.selectedRows', selectedRows!.length) }}
                </span>
                <button
                  v-if="sectionClick"
                  type="button"
                  class="p-2 -ml-0.5"
                  @click="sectionClick(sectionId)"
                >
                  <PencilSquareIcon class="w-4 h-4 text-text-primary" />
                </button>
              </div>

              <slot name="message" />
            </div>
            <slot
              name="leftActions"
            />
          </div>
          <div class="flex items-center gap-x-2">
            <div
              v-if="!showTabs"
              class="flex items-center gap-x-2"
            >
              <ViewsPanel
                v-if="withViews && isServerFilters"
                v-model:filters="filtersController"
              />

              <BulkEditButton
                v-if="canBulkUpdate"
                :disabled="selectedRows.length <= 0"
                @click="onClickBulkEdit"
              />

              <FiltersPanel
                v-if="isServerFilters && availableFilters.length"
                v-model:filters="filtersController"
                :available-filters="availableFilters"
              />

              <PropertiesPanel
                v-if="withProperties"
                v-model:columns="dtColumns"
              />

              <SortsPanel
                v-model:filters="filtersController"
                :columns="columns"
              />

              <SearchPanel
                v-if="isServerFilters && withSearch"
                v-model:filters="filtersController"
              />
            </div>
            <slot
              name="rightAction"
              :button-ref="disclosureButtonRef"
            />
          </div>
        </div>

        <slot name="information" />

        <div
          v-if="showTabs"
          class="relative flex flex-row items-start mt-4 border-b-2 border-gray-300"
        >
          <div class="grow">
            <Tabs
              :tabs="tabs"
              :is-current-tab="isCurrentView"
            />
          </div>
          <div class="flex items-center justify-start whitespace-nowrap">
            <ViewsPanel
              v-model:filters="filtersController"
            />
          </div>
        </div>
        <div
          v-if="showTabs"
          class="flex justify-end space-x-2"
        >
          <div
            class="flex items-center space-x-2"
          >
            <BulkEditButton
              v-if="canBulkUpdate"
              :disabled="selectedRows.length <= 0"
              @click="onClickBulkEdit"
            />

            <FiltersPanel
              v-if="isServerFilters && availableFilters.length"
              v-model:filters="filtersController"
              :available-filters="availableFilters"
            />

            <PropertiesPanel
              v-if="withProperties"
              v-model:columns="dtColumns"
            />

            <SortsPanel
              v-model:filters="filtersController"
              :columns="columns"
            />

            <SearchPanel
              v-if="isServerFilters && withSearch"
              v-model:filters="filtersController"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col h-full overflow-hidden">
        <slot />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue'
import { ChevronRightIcon, PencilSquareIcon } from '@heroicons/vue/24/solid'
import { AxiosResponse } from 'axios'
import { PropType, computed, defineComponent, onMounted, ref, useSlots, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'
import { useRoute, useRouter } from 'vue-router'

import { Paginate } from '@/types/paginate'

import { Column, Row } from '@/plugins/datatable/datatable.d'
import { extractResponse } from '@/plugins/datatable/utils'
import { ColumnFilter } from '@/plugins/filters'
import { BaseFilters, ServerFilters } from '@/plugins/filters/filters'

import { useAppStore } from '@/store/app.store'
import { useCounterStore } from '@/store/counter.store'
import { useViewStore } from '@/store/view.store'

import { View } from '@/models/view'

import FiltersPanel from '../DatatableFilters/FiltersPanel.vue'
import PropertiesPanel from '../DatatableFilters/PropertiesPanel.vue'
import SearchPanel from '../DatatableFilters/SearchPanel.vue'
import SortsPanel from '../DatatableFilters/SortsPanel.vue'
import ViewsPanel from '../DatatableFilters/Views/ViewsPanel.vue'
import Tabs, { Tab } from '../Tabs/Tabs.vue'

import BulkEditButton from './ContentBulkEditButton.vue'

export default defineComponent({
  components: {
    PencilSquareIcon,
    ChevronRightIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    PropertiesPanel,
    FiltersPanel,
    SearchPanel,
    SortsPanel,
    ViewsPanel,
    BulkEditButton,
    Tabs
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Section title'
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String as PropType<'base' | 'disclosure'>,
      required: false,
      default: 'base'
    },
    isDefaultOpen: { // Disclosure.default option
      type: Boolean,
      required: false,
      default: () => true
    },
    columns: {
      type: Array as PropType<Column[]>,
      required: false,
      default: () => []
    },
    availableFilters: {
      type: Array as PropType<ColumnFilter[]>,
      required: false,
      default: () => []
    },
    sectionId: {
      type: String,
      required: false,
      default: ''
    },
    sectionClick: {
      type: Function,
      required: false,
      default: null
    },
    filters: {
      type: Object as PropType<BaseFilters>,
      required: true
    },
    selectedRows: {
      type: Array as PropType<Row[]>,
      default: () => []
    },
    canBulkUpdate: {
      type: Boolean,
      default: false
    },
    noPadding: { // Removes horizontal padding from the main container so the Content body has no horizontal padding
      type: Boolean,
      default: false
    },
    withSearch: {
      type: Boolean,
      default: true
    },
    withViews: {
      type: Boolean,
      default: true
    },
    withProperties: {
      type: Boolean,
      default: true
    },
    fetchData: {
      type: Function as PropType<(queryParams?: Object) => Promise<AxiosResponse<Paginate<unknown>>>>,
      required: false,
      default: undefined
    }
  },
  emits: ['update:columns', 'update:filters', 'open:bulk-edit'],
  setup (props, { emit }) {
    const currentRoute = useRoute()
    const router = useRouter()
    const slots = useSlots()

    const appStore = useAppStore()
    const viewStore = useViewStore()
    const counterStore = useCounterStore()

    const disclosureButtonRef = ref<any>()
    const { t } = useI18n()

    const dtColumns = computed({
      get () {
        return props.columns
      },
      set (columns: Column[]) {
        emit('update:columns', columns)
      }
    })

    const isServerFilters = computed(() => props.filters instanceof ServerFilters)

    const filtersController = computed({
      get () {
        return props.filters
      },
      set (value: BaseFilters) {
        emit('update:filters', value)
      }
    })

    const onClickBulkEdit = () => {
      appStore.openPanel()
      emit('open:bulk-edit')
    }

    // Tabs Views (also named as "pinned views")
    const pinnedViews = computed<View[]>(() => {
      if (!isServerFilters.value) {
        return []
      }
      return viewStore.pinnedViews.filter(v => v.pageContext === (filtersController.value as ServerFilters).tableName)
    })

    const defaultView = computed(() => {
      if (!isServerFilters.value) {
        return null
      }
      return viewStore.defaultViews.find(v => v.pageContext === (filtersController.value as ServerFilters).tableName && v.isDefault) || null
    })

    const isCurrentView = (key: string): boolean => {
      if (key === 'default') {
        if (defaultView.value) {
          if (currentRoute.query.view) {
            return currentRoute.query.view === defaultView.value.id.toString()
          }
        }

        if (!currentRoute.query.view) {
          return true
        }
      }

      if (currentRoute.query.view) {
        return currentRoute.query.view === key
      }

      return false
    }

    const defaultCount = ref<number | null>(null)

    const { run: runDefaultCount } = props.fetchData
      ? useRequest(props.fetchData, {
        defaultParams: [{ per_page: 1 }],
        manual: true,
        onSuccess: (response) => {
          if (response?.data) {
            const { pagination } = extractResponse(response.data)
            defaultCount.value = pagination.total
          }
        }
      })
      : { run: null }

    const fetchDefaultCount = () => {
      if (pinnedViews.value.length && runDefaultCount) {
        runDefaultCount({ per_page: 1 })
      }
    }

    onMounted(() => {
      fetchDefaultCount()
    })

    watch(
      router.currentRoute,
      () => fetchDefaultCount()
    )

    const tabs = computed(() => {
      if (isServerFilters.value && !props.withViews) {
        return []
      }

      if (!pinnedViews.value.length) {
        return []
      }

      const tabs: Tab[] = [
        {
          key: 'default',
          name: 'All',
          onClick: () => {
            if (isServerFilters.value) {
              if (isCurrentView('default')) {
                return
              }
              const f = filtersController.value as ServerFilters
              f.currentView.value = defaultView.value
              filtersController.value = f
            }
          },
          counter: {
            count: defaultCount.value || 0,
            type: 'default'
          }
        },
        ...pinnedViews.value.map((v) => {
          return {
            key: v.id.toString(),
            name: v.name,
            onClick: () => {
              if (isServerFilters.value) {
                if (isCurrentView(v.id.toString())) {
                  return
                }
                const f = filtersController.value as ServerFilters
                f.currentView.value = v
                filtersController.value = f
              }
            },
            counter: v.countKey ? counterStore.counters[v.countKey] : undefined
          }
        })
      ]

      return tabs
    })

    const showTabs = computed(() => isServerFilters.value && tabs.value.length && props.withViews)

    return {
      slots,
      t,
      disclosureButtonRef,
      dtColumns,
      filtersController,
      isServerFilters,
      onClickBulkEdit,

      // Views
      showTabs,
      isCurrentView,
      tabs
    }
  }
})
</script>
