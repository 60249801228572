<template>
  <div class="flex flex-col min-h-screen px-2 py-8 lg:flex-row lg:p-0">
    <div class="lg:w-1/2 lg:flex lg:flex-col lg:justify-center">
      <main class="justify-center sm:flex">
        <p class="text-4xl font-extrabold text-primary-600 sm:text-5xl">
          404
        </p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-500 sm:pl-6">
            <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              {{ t('labels.pageNotFound') }}
            </h1>
            <p class="mt-1 text-base text-gray-700">
              {{ t('labels.pageNotFoundNotice') }}
            </p>
          </div>
          <div class="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <AppLink
              :to="{ name: 'home' }"
              appearance="primary"
              size="sm"
            >
              {{ t('labels.goBackHome') }}
            </AppLink>
          </div>
        </div>
      </main>
    </div>
    <div
      class="hidden lg:block lg:flex-1 lg:relative lg:ml-auto"
    >
      <span
        class="sticky block w-full h-full bg-left-top bg-no-repeat bg-cover"
        :style="`background-image: url(${storageUrl}/img/adagio-connexion.png);`"
      />
    </div>
    <div
      class="fixed -z-10 pointer-events-none h-[40%] sm:h-[55%] w-full bottom-0 right-0 lg:hidden"
    >
      <span
        class="block w-full h-full bg-left-top bg-no-repeat bg-cover"
        :style="`background-image: url(${storageUrl}/img/adagio-connexion-mobile.png);`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import AppLink from '@/components/Buttons/AppLink.vue'

export default defineComponent({
  components: {
    AppLink
  },
  setup () {
    const { t } = useI18n()
    const storageUrl = import.meta.env.VITE_STORAGE_URL
    return {
      t,
      storageUrl
    }
  }
})
</script>
