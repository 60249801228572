import replace from 'lodash/replace'
import { RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import { useRouteContext } from '@/plugins/context'
import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'
import { getPermission, useRoutePermissions } from '@/plugins/permissions'
import segment from '@/plugins/segmentio'

import { useAppStore } from '@/store/app.store'
import { useAuthStore } from '@/store/auth.store'
import { useContextStore } from '@/store/context.store'
import { useContextNotificationsStore } from '@/store/contextNotifications.store'
import { useNotificationsStore } from '@/store/notifications.store'
import { useProfileStore } from '@/store/profile.store'

import { refreshToken } from '@/services/auth'

import { adapters } from '@/features/adapters/route'
import { bidderAdstxt } from '@/features/adstxt/bidder/route'
import { publisherAdstxt } from '@/features/adstxt/publisher/route'
import { apikeys } from '@/features/apiKeys/route'
import { bidders, homeBidders } from '@/features/bidders/route'
import { blocklists } from '@/features/blocklistRules/route'
import { demandLibrary } from '@/features/demandLibrary/route'
import { floorRules } from '@/features/floorRules/route'
import { groups, homeGroups } from '@/features/groups/route'
import { invitations } from '@/features/invitations/route'
import { invites } from '@/features/invites/route'
import { invoices } from '@/features/invoiceRequests/route'
import { bidderMappingRules } from '@/features/mappingRules/bidder/route'
import { publisherMappingrules } from '@/features/mappingRules/publisher/route'
import { optimRules } from '@/features/optimRules/route'
import { optins } from '@/features/optins/route'
import { homePublishers, publishers } from '@/features/publishers/route'
import { refreshRules } from '@/features/refreshRules/route'
import { reports } from '@/features/reports/route'
import { bidderSeats } from '@/features/seats/bidder/route'
import { publisherSeats } from '@/features/seats/publisher/route'
import { seatsBoosterRules } from '@/features/seatsBoosterRules/route'
import { settings } from '@/features/settings/route'
import { bidderShapingRules } from '@/features/shapingRules/bidder/route'
import { publisherShapingRules } from '@/features/shapingRules/publisher/route'
import { splitRules } from '@/features/splitRules/route'
import { troubleshootRules } from '@/features/troubleshootRules/route'
import { users } from '@/features/users/route'
import { websites, websitesGroups } from '@/features/websites/route'
import { bidderWhitelists } from '@/features/whitelists/bidder/route'
import { publisherWhitelists } from '@/features/whitelists/publisher/route'
import { winningAdvertisers } from '@/features/winningAdvertisers/route'

import { login, logout } from '../features/auth/route'
import { dashboards } from '../features/dashboards/route'
import { passwords } from '../features/passwords/route'
import GuestLayout from '../layouts/Guest.vue'
import { removeMeta, useMeta, watchMeta } from '../plugins/meta/index'
import NotFound from '../views/NotFound.vue'

import { authGuard, defaultRedirection, guestGuard } from './guards/auth'
import { hasBidder, hasGroup, hasPublisher } from './guards/context'
import { authInviteGuard, inviteGuard } from './guards/invite'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    beforeEnter: [guestGuard, authInviteGuard],
    component: GuestLayout,
    redirect: { name: 'login' },
    children: [
      login,
      ...passwords
    ]
  },
  {
    path: '/invite',
    beforeEnter: inviteGuard,
    component: GuestLayout,
    redirect: { name: 'invitationJoin' },
    children: invites
  },
  {
    path: '/',
    beforeEnter: authGuard,
    component: () => import('../layouts/Auth.vue'),
    redirect: {
      name: 'home'
    },
    meta: {
      title: 'auth',
      hideTitle: true,
      authenticated: true
    },
    children: [
      {
        path: '',
        beforeEnter: defaultRedirection,
        name: 'home',
        meta: { title: i18n.global.t('labels.home') },
        component: () => import('../views/Home.vue'),
        children: [
          ...homePublishers(ContextType.PUBLISHERS),
          ...homeBidders(ContextType.BIDDERS),
          ...homeGroups(ContextType.GROUPS)
        ]
      },
      {
        name: 'publishers',
        path: 'publishers/:publisherId',
        component: () => import('../layouts/Default.vue'),
        props: true,
        meta: {
          title: i18n.global.t('labels.publisher', 2),
          pattern: ':publisherName',
          hasPublisher: true
        },
        redirect: {
          name: 'publishers.home'
        },
        children: [
          ...publishers(ContextType.PUBLISHERS),
          ...websites(),
          ...dashboards(ContextType.PUBLISHERS),
          ...blocklists(),
          ...apikeys(ContextType.PUBLISHERS),
          ...invitations(ContextType.PUBLISHERS),
          ...users(ContextType.PUBLISHERS),
          ...floorRules(),
          ...refreshRules(),
          ...publisherMappingrules(ContextType.PUBLISHERS),
          ...splitRules(),
          ...reports(ContextType.PUBLISHERS),
          ...publisherShapingRules(ContextType.PUBLISHERS),
          ...publisherSeats(ContextType.PUBLISHERS),
          ...publisherWhitelists(ContextType.PUBLISHERS),
          ...publisherAdstxt(ContextType.PUBLISHERS),
          ...demandLibrary(),
          ...winningAdvertisers(),
          ...invoices(ContextType.PUBLISHERS)
        ]
      },
      {
        name: 'bidders',
        path: 'bidders/:bidderId',
        component: () => import('../layouts/Default.vue'),
        props: true,
        meta: {
          title: i18n.global.t('labels.bidder', 2),
          pattern: ':bidderName',
          hasBidder: true
        },
        redirect: {
          name: 'bidders.home'
        },
        children: [
          ...dashboards(ContextType.BIDDERS),
          ...reports(ContextType.BIDDERS),
          ...adapters(),
          ...bidders(ContextType.BIDDERS),
          ...bidderSeats(ContextType.BIDDERS),
          ...optins(ContextType.BIDDERS),
          ...bidderWhitelists(ContextType.BIDDERS),
          ...bidderAdstxt(ContextType.BIDDERS),
          ...apikeys(ContextType.BIDDERS),
          ...invitations(ContextType.BIDDERS),
          ...users(ContextType.BIDDERS),
          ...bidderMappingRules(),
          ...bidderShapingRules()
        ]
      },
      {
        name: 'groups',
        path: 'groups/:groupId',
        component: () => import('../layouts/Default.vue'),
        props: true,
        meta: {
          title: i18n.global.t('labels.group', 2),
          pattern: ':groupName',
          hasGroup: true
        },
        redirect: {
          name: 'groups.home'
        },
        children: [
          ...groups,
          ...dashboards(ContextType.GROUPS),
          ...websitesGroups(),
          ...apikeys(ContextType.GROUPS),
          ...invitations(ContextType.GROUPS),
          ...users(ContextType.GROUPS),
          ...seatsBoosterRules(),
          ...optimRules(),
          ...invoices(ContextType.GROUPS),
          ...troubleshootRules()
        ]
      },
      settings,
      logout
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to) => {
  useRouteContext(to)
  return true
})

router.beforeEach(async (to) => {
  const authStore = useAuthStore()
  if (to.meta.authenticated && !authStore.isFreshToken) {
    try {
      const response = await refreshToken()
      if (response && response.data) {
        authStore.setAccessToken(response.data.accessToken)
        authStore.setAccessTokenExpiration(response.data.expiration)
      }
    } catch (e) {
      authStore.logout()
      return { name: 'login', query: { redirect: to.fullPath } }
    }
  }
})

router.beforeEach(async (to, from) => {
  const contextNotificationsStore = useContextNotificationsStore()

  if (!contextChanged(to, from)) {
    // The context hasn't changed, no need to fetch its info again
    return
  }
  if (to.meta.hasGroup && !(await hasGroup(to))) {
    return false
  }
  if (to.meta.hasPublisher && !(await hasPublisher(to))) {
    return false
  }
  if (to.meta.hasBidder && !(await hasBidder(to))) {
    return false
  }

  // contextNotificationStore.clearInterval() is called when the context has changed.
  // Ensure it is called when we are on a non context route.
  contextNotificationsStore.clearInterval()
})

router.beforeEach((to, from, next) => {
  const appStore = useAppStore()

  // Set the proper app layout
  const toLayout = to.meta?.layout
  const fromLayout = from.meta?.layout

  if (toLayout !== fromLayout) {
    if (toLayout !== undefined) {
      appStore.setIsContentHeightFull(!!(toLayout === 'heightFull'))
    } else {
      // default we want to use the `heightFull` layout
      appStore.setIsContentHeightFull(true)
    }
  }

  if (canSaveQueryParams(to, from)) {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      next({ path: to.path, query: from.query })
      return
    }
  }

  next()
})

router.beforeResolve((to, from) => {
  const notificationsStore = useNotificationsStore()
  const authStore = useAuthStore()
  const permissions = useRoutePermissions(to)

  if (!permissions.hasAccess) {
    // If there is no previous route, redirect to home or login
    // Otherwise cancel navigation and show error
    if (from.matched.length === 0) { // No previous route
      return { name: authStore.isLogged ? 'home' : 'login' }
    }
    notificationsStore.add({
      message: i18n.global.t('messages.permissionError'),
      type: 'error',
      duration: 4000
    })
    return false
  }
})

let lastTo: RouteLocationNormalized
const updateTitle = (): void => {
  if (lastTo && lastTo.meta) {
    if (lastTo.meta.pattern) {
      const title = useMeta(lastTo.meta.pattern as string)

      if (lastTo.meta.title) {
        const titleWithPattern = replace(lastTo.meta.title as string, lastTo.meta.pattern as string, title)

        if (titleWithPattern !== '') {
          document.title = `${titleWithPattern} • Adagio`
        }
      }
      return
    }

    if (lastTo.meta.title) {
      document.title = `${lastTo.meta.title} • Adagio`
      return
    }
  }

  // Fallback if pageTitle is not set
  document.title = 'Adagio'
}

watchMeta(updateTitle)

router.beforeEach((to, from) => {
  if (from?.meta?.pattern) {
    if (to?.meta?.pattern && to?.meta?.pattern === from?.meta?.pattern) {
      return
    }
    removeMeta(from.meta.pattern)
  }
})

router.beforeEach((to, from) => {
  const contextStore = useContextStore()
  const profileStore = useProfileStore()

  const permission = getPermission(contextStore.context)
  segment.page(undefined, to.name?.toString(), {
    path: to.fullPath,
    isHome: from.name === 'home',
    viewMode: profileStore.isCustomerView ? 'customer' : 'admin',
    roles: permission?.roles
  })
})

router.beforeEach((to) => {
  lastTo = to
  updateTitle()
})

function canSaveQueryParams (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean {
  if (to.meta.feature && from.meta.feature) {
    return to.meta.feature === from.meta.feature
  }

  return false
}

function hasQueryParams (route: RouteLocationNormalized): boolean {
  return !!Object.keys(route.query).length
}

function contextChanged (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean {
  const changed = [['hasGroup', 'groupId'], ['hasPublisher', 'publisherId'], ['hasBidder', 'bidderId']].some(m => {
    return to.meta[m[0]] !== from.meta[m[0]] || to.params[m[1]] !== from.params[m[1]]
  })

  return changed
}
