import { addMilliseconds, differenceInDays, endOfDay, format, isBefore, parse, startOfDay } from 'date-fns'
import { reactive, ref, Ref, watch, WatchStopHandle } from 'vue'
import { LocationQueryRaw, LocationQueryValue, RouteLocationNormalized, Router } from 'vue-router'

import { DateRangePreset, DATE_RANGE_PRESETS } from '@/utils/dateRange'

import { ComparisonRangePreset, COMPARISON_DATE_RANGE_PRESETS, DateRange } from '@/components/DateRangePicker/dateRange'

import { Filters, FilterValues } from './source'

const DATE_FORMAT = 'yyyy-MM-dd'

export class QueryBuilder {
  router: Router
  filters: Filters
  enlargedWidgetID: Ref<string | undefined>
  widgetMetrics: Record<string, string>
  widgetSorts: Record<string, [string, boolean]>
  watchStop: WatchStopHandle
  watchEnlargedStop: WatchStopHandle

  constructor (filters: Filters, router: Router) {
    this.router = router
    this.filters = filters
    this.enlargedWidgetID = ref<string|undefined>()
    this.widgetMetrics = reactive<Record<string, string>>({})
    this.widgetSorts = reactive<Record<string, [string, boolean]>>({})

    this.watchStop = watch(
      () => [filters, this.widgetMetrics, this.widgetSorts],
      () => {
        this.updateQuery()
      },
      { deep: true }
    )

    this.watchEnlargedStop = watch(
      () => this.enlargedWidgetID.value,
      () => {
        this.updateQuery()
      }
    )
  }

  dispose (): void {
    this.watchStop()
    this.watchEnlargedStop()
  }

  updateQuery (): void {
    this.router.replace({ ...this.router.currentRoute.value, query: this.format() })
  }

  format (): LocationQueryRaw {
    const query: LocationQueryRaw = {}
    const filterEntries = Object.entries(this.filters.filters)
    if (filterEntries.length) {
      query.filters = filterEntries.map(v => this.formatFilterValues(v[0], v[1])).join(';')
    }

    query.dateRange = this.formatDateRange()
    query.compare = this.formatComparisonDateRange()

    query.enlarged = this.enlargedWidgetID.value
    query.metrics = this.formatWidgetMetrics()
    query.sorts = this.formatWidgetSorts()

    return query
  }

  formatFilterValues (dimension: string, filterValues: FilterValues): string {
    const operator = filterValues.operator === 'equals' ? '=' : '!='
    return dimension + operator + filterValues.values.join(',') // FIXME can filters contain comma?
  }

  formatDateRange (): string {
    return [format(this.filters.dateRange.from, DATE_FORMAT), format(this.filters.dateRange.to, DATE_FORMAT)].join(',')
  }

  formatComparisonDateRange (): string | undefined {
    const range = this.filters.comparisonDateRange
    if (!range.from || !range.to) {
      return
    }

    const comparisonPreset = Object.entries(COMPARISON_DATE_RANGE_PRESETS).find(e => {
      const r = e[1]({
        start: this.filters.dateRange.from,
        end: this.filters.dateRange.to
      })
      return r.start.getTime() === range.from!.getTime() && r.end.getTime() === range.to!.getTime()
    })

    return comparisonPreset?.[0]
  }

  formatWidgetMetrics (): string | undefined {
    const entries = Object.entries(this.widgetMetrics)
    if (entries.length === 0) {
      return undefined
    }
    return entries.map(e => `${e[0]}=${e[1]}`).join(';')
  }

  formatWidgetSorts (): string | undefined {
    const entries = Object.entries(this.widgetSorts)
    if (entries.length === 0) {
      return undefined
    }

    return entries.map(e => `${e[0]}=${e[1][0]},${e[1][1] ? 'ASC' : 'DESC'}`).join(';')
  }

  setEnlarged (widgetID: string | undefined): void {
    this.enlargedWidgetID.value = widgetID
  }

  setMetric (widgetID: string, metric: string | undefined): void {
    if (!metric) {
      delete this.widgetMetrics[widgetID]
      return
    }

    this.widgetMetrics[widgetID] = metric
  }

  setSort (widgetID: string, metric: string | undefined | null, ascending: boolean): void {
    if (!metric) {
      delete this.widgetSorts[widgetID]
      return
    }

    this.widgetSorts[widgetID] = [metric, ascending]
  }

  parseQuery (route: RouteLocationNormalized, dst: Filters, defaultPeriod: DateRange | DateRangePreset, maxDaysRange: number, defaultComparisonPeriod: ComparisonRangePreset | undefined): void {
    for (const key in dst.filters) {
      delete dst.filters[key]
    }
    Object.entries(parseFilters(route.query.filters)).forEach(e => {
      dst.filters[e[0]] = e[1]
    })

    const dateRange = parseDateRange(route.query.dateRange, defaultPeriod, maxDaysRange)
    dst.dateRange.from = dateRange.start
    dst.dateRange.to = dateRange.end
    const comparisonDateRange = parseComparisonDateRange(route.query.compare, dateRange, defaultComparisonPeriod)
    dst.comparisonDateRange.from = comparisonDateRange?.start
    dst.comparisonDateRange.to = comparisonDateRange?.end

    this.enlargedWidgetID.value = parseWidgetID(route.query.enlarged)

    for (const key in this.widgetMetrics) {
      delete this.widgetMetrics[key]
    }
    parseWidgetMetrics(route.query.metrics).forEach(m => {
      this.widgetMetrics[m[0]] = m[1]
    })
    parseWidgetSorts(route.query.sorts).forEach(s => {
      this.widgetSorts[s[0]] = [s[1], s[2]]
    })
  }
}

function parseDateRange (query: LocationQueryValue | LocationQueryValue[], defaultPeriod: DateRange | DateRangePreset, maxDaysRange: number): DateRange {
  if (!query || Array.isArray(query)) {
    return getDefaultPeriod(defaultPeriod)
  }

  const dates = query.split(',')
  if (dates.length !== 2) {
    return getDefaultPeriod(defaultPeriod)
  }

  const dateRange: DateRange = {
    start: startOfDay(parse(dates[0], DATE_FORMAT, new Date())),
    end: endOfDay(parse(dates[1], DATE_FORMAT, new Date()))
  }

  if (
    isNaN(dateRange.start.getTime()) ||
    isNaN(dateRange.end.getTime()) ||
    isBefore(dateRange.end, dateRange.start) ||
    differenceInDays(addMilliseconds(dateRange.end, 1), dateRange.start) > maxDaysRange) {
    return getDefaultPeriod(defaultPeriod)
  }

  return dateRange
}

function getDefaultPeriod (defaultPeriod: DateRange | DateRangePreset): DateRange {
  if (typeof defaultPeriod === 'object') { // We have a manual DateRange
    return defaultPeriod
  }
  return DATE_RANGE_PRESETS[defaultPeriod]()
}

function parseComparisonDateRange (query: LocationQueryValue | LocationQueryValue[], dateRange: DateRange, defaultComparisonPeriod: ComparisonRangePreset | undefined): DateRange | undefined {
  if (!query || Array.isArray(query) || COMPARISON_DATE_RANGE_PRESETS[query] === undefined) {
    return defaultComparisonPeriod ? COMPARISON_DATE_RANGE_PRESETS[defaultComparisonPeriod](dateRange) : undefined
  }

  return COMPARISON_DATE_RANGE_PRESETS[query](dateRange)
}

function parseFilters (query: LocationQueryValue | LocationQueryValue[]): Record<string, FilterValues> {
  if (!query || Array.isArray(query)) {
    return {}
  }

  const filters: Record<string, FilterValues> = {}

  query.split(';').forEach(dim => {
    const equalsIndex = dim.indexOf('=')
    const notEqualsIndex = dim.indexOf('!=')
    let dimension = ''
    let values = ''
    let operator: 'equals' | 'notEquals' = 'equals'
    if (notEqualsIndex !== -1) {
      operator = 'notEquals'
      dimension = dim.slice(0, notEqualsIndex)
      values = dim.slice(notEqualsIndex + 2)
    } else if (equalsIndex !== -1) {
      dimension = dim.slice(0, equalsIndex)
      values = dim.slice(equalsIndex + 1)
    } else {
      return
    }

    if (dimension === '') {
      return
    }

    filters[dimension] = {
      operator,
      values: values.split(',')
    }
  })

  return filters
}

function parseWidgetID (query: LocationQueryValue | LocationQueryValue[]): string | undefined {
  if (!query || Array.isArray(query)) {
    return undefined
  }

  return query
}

function parseWidgetMetrics (query: LocationQueryValue | LocationQueryValue[]): Array<[string, string]> {
  if (!query || Array.isArray(query)) {
    return []
  }

  return query.split(';').map(v => v.split('=')).filter(v => v.length === 2).map(v => ([v[0], v[1]]))
}

function parseWidgetSorts (query: LocationQueryValue | LocationQueryValue[]): Array<[string, string, boolean]> {
  if (!query || Array.isArray(query)) {
    return []
  }

  return query.split(';')
    .map(v => v.split('='))
    .filter(v => v.length === 2)
    .map(v => ([v[0], v[1].split(',')] as [string, string[]]))
    .filter(v => v[1].length === 2 && (v[1][1] === 'ASC' || v[1][1] === 'DESC'))
    .map(v => [v[0], v[1][0], v[1][1] === 'ASC'])
}
