import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function bidderShapingRules (): RouteRecordRaw[] {
  const context = ContextType.BIDDERS
  return [
    {
      path: 'shaping-rules',
      name: `${context}.shapingRules.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.shapingRule', 2),
        roles: rolesGroup.all
      },
      children: [
        {
          path: 'create',
          name: `${context}.shapingRules.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('shapingRules.create'),
            featuresPermissions: ['shaping_rules'],
            requireService: true
          }
        },
        {
          path: ':shapingRuleId',
          name: `${context}.shapingRules.show`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('shapingRules.show', { name: ':shapingRuleName' }),
            pattern: ':shapingRuleName'
          },
          props: route => ({ shapingRuleId: route.params.shapingRuleId })
        },
        {
          path: ':shapingRuleId/edit',
          name: `${context}.shapingRules.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('shapingRules.edit', { name: ':shapingRuleName' }),
            pattern: ':shapingRuleName',
            featuresPermissions: ['shaping_rules']
          },
          props: route => ({ shapingRuleId: route.params.shapingRuleId })
        }
      ]
    }
  ]
}
