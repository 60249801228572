<template>
  <Panel
    :open-on-mount="false"
    :title="t('labels.bulkEdit')"
  >
    <PanelForm
      :on-submit="onSubmit"
      :on-cancel="onCancel"
      :is-submitting="state.isSubmitting"
      :is-disabled="!selectedRows.length"
      :no-validation="true"
      :with-confirm="withConfirm"
      :with-confirm-options="withConfirmOptions"
    >
      <div class="divide-y divide-gray-border">
        <FormFields
          v-model="form"
          :fields="fields"
          :errors="errors"
        />
        <div class="p-5">
          <Collapse
            as="div"
            :open-by-default="true"
          >
            <template #header>
              <div class="flex flex-row items-center space-x-1.5">
                <div class="flex flex-col text-left">
                  <span class="font-semibold text-text-primary">{{ t('labels.row', 2) }}</span>
                </div>
              </div>
            </template>
            <div
              class="mt-5 space-y-2.5"
            >
              <Datatable
                v-model:pagination="previewPagination"
                :items="paginatedRows"
                :selected-rows="selectedRows"
                :columns="computedColumns"
                select-with="none"
              >
                <template
                  v-for="col in computedColumns"
                  :key="col.field"
                  #[col.slotName]="{ value, row }"
                >
                  <ApprovalStatusComponent
                    v-if="col.renderingType === 'approvalStatus' || col.field.endsWith('OptinStatus')"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <ArrayComponent
                    v-else-if="col.renderingType === 'array'"
                    :value="value || []"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <ToggleComponent
                    v-else-if="['boolean', 'toogle'].includes(col.renderingType as string)"
                    v-model="row.data[col.field]"
                    :is-disabled="true"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <CurrencyComponent
                    v-else-if="col.renderingType === 'currency'"
                    :value="value || []"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <DateComponent
                    v-else-if="col.renderingType === 'date'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <EnumComponent
                    v-else-if="col.renderingType === 'enum'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <LetterComponent
                    v-else-if="col.renderingType === 'letter'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <ListComponent
                    v-else-if="col.renderingType === 'list'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <PriorityComponent
                    v-else-if="col.renderingType === 'priority'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <TargetableComponent
                    v-else-if="col.renderingType === 'targetable'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <TruncateComponent
                    v-else-if="col.renderingType === 'truncate'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <WarningComponent
                    v-else-if="col.renderingType === 'warning'"
                    :error="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <WebsiteStatus
                    v-else-if="col.renderingType === 'websiteStatus'"
                    :status="value"
                  />
                  <CountryComponent
                    v-else-if="col.renderingType === 'country'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <LargeNumberComponent
                    v-else-if="col.renderingType === 'largeNumber'"
                    :value="value"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <component
                    :is="col.renderer!(row)"
                    v-else-if="col.renderingType === 'custom'"
                    v-bind="col.attrs ? col.attrs(value, row) : {}"
                  />
                  <template v-else>
                    {{ value }}
                  </template>
                </template>
              </Datatable>
            </div>
          </Collapse>
        </div>
      </div>

      <template #modal-text>
        <slot name="modal-text" />
      </template>
    </PanelForm>
  </Panel>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios'
import { isValid } from 'date-fns'
import { computed, defineComponent, PropType, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { PaginateWithoutRecords } from '@/types/paginate'

import { ConfirmOptions } from '@/composables/useConfirm'

import ArrayComponent from '@/plugins/datatable/Components/ArrayComponent.vue'
import DateComponent from '@/plugins/datatable/Components/DateComponent.vue'
import EnumComponent from '@/plugins/datatable/Components/EnumComponent.vue'
import PriorityComponent from '@/plugins/datatable/Components/PriorityComponent.vue'
import ToggleComponent from '@/plugins/datatable/Components/ToggleComponent.vue'
import Datatable from '@/plugins/datatable/Datatable.vue'

import Collapse from '@/components/Collapse/Collapse.vue'
import FormFields from '@/components/FormBuilder/FormFields.vue'
import Panel from '@/components/Panel/Panel.vue'
import PanelForm from '@/components/Panel/PanelForm.vue'
import WebsiteStatus from '@/features/websites/components/WebsiteStatus.vue'

import { BulkOptions, useBulkForm } from './bulk'
import ApprovalStatusComponent from './Components/ApprovalStatusComponent.vue'
import CountryComponent from './Components/CountryComponent.vue'
import CurrencyComponent from './Components/CurrencyComponent.vue'
import LargeNumberComponent from './Components/LargeNumberComponent.vue'
import LetterComponent from './Components/LetterComponent.vue'
import ListComponent from './Components/ListComponent.vue'
import TargetableComponent from './Components/TargetableComponent.vue'
import TruncateComponent from './Components/TruncateComponent.vue'
import WarningComponent from './Components/WarningComponent.vue'
import { Column, FieldType, Row } from './datatable.d'

export default defineComponent({
  components: {
    Panel,
    PanelForm,
    FormFields,
    Collapse,
    Datatable,
    ArrayComponent,
    DateComponent,
    ToggleComponent,
    EnumComponent,
    PriorityComponent,
    TruncateComponent,
    WarningComponent,
    TargetableComponent,
    ListComponent,
    LetterComponent,
    CurrencyComponent,
    ApprovalStatusComponent,
    WebsiteStatus,
    CountryComponent,
    LargeNumberComponent
  },
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true
    },
    prefix: {
      type: String as PropType<string>,
      default: undefined
    },
    api: {
      type: Function as PropType<(form: any) => Promise<AxiosResponse>>,
      default: undefined
    },
    submitHandler: {
      type: Function as PropType<(form: any) => Promise<AxiosResponse | Array<AxiosResponse<any>>>>,
      required: false,
      default: undefined
    },
    selectedRows: {
      type: Array as PropType<Row[]>,
      required: true
    },
    withConfirmFn: {
      type: Function as PropType<(form: any, validation: any) => boolean>,
      required: false,
      default: undefined
    },
    withConfirmOptions: {
      type: Object as PropType<ConfirmOptions | undefined>,
      required: false,
      default: undefined
    }

  },
  emits: ['success', 'error'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const route = useRoute()
    const selectedRowsRef = ref<Row[]>([])
    const computedColumns = computed(() => props.columns.map(c => {
      const copy = Object.assign({}, c) as any
      copy.sortable = false
      copy.slotName = copy.field.replaceAll('.', '__')
      return copy
    }))

    watchEffect(
      () => { selectedRowsRef.value = props.selectedRows }
    )

    const onSuccess = () => {
      bulkForm.reset()
      previewPagination.value = defaultPagination()
      emit('success')
    }

    const onError = () => {
      emit('error')
    }

    const opts: BulkOptions = {
      selectedRows: selectedRowsRef,
      columns: props.columns,
      prefix: props.prefix,
      api: props.api,
      submitHandler: props.submitHandler,
      onSuccess,
      onError
    }
    const bulkForm = useBulkForm(opts)

    const perPage = 10
    const maxPage = () => {
      const maxPage = Math.ceil(props.selectedRows.length / perPage)
      return maxPage === 0 ? 1 : maxPage
    }
    const defaultPagination = () => ({
      currentPage: 1,
      maxPage: maxPage(),
      total: props.selectedRows.length,
      pageSize: perPage
    } as PaginateWithoutRecords)
    const previewPagination = ref<PaginateWithoutRecords>(defaultPagination())

    const onCancel = () => {
      bulkForm.reset()
      previewPagination.value = defaultPagination()
    }

    watch(
      () => props.selectedRows.length,
      () => {
        const cpy = Object.assign({}, previewPagination.value)
        cpy.maxPage = maxPage()
        cpy.total = props.selectedRows.length
        previewPagination.value = cpy
      },
      { immediate: true }
    )

    const paginatedRows = computed(() => props.selectedRows.slice((previewPagination.value.currentPage - 1) * perPage, previewPagination.value.currentPage * perPage))

    const withConfirm = ref(false)
    watch(
      () => bulkForm.form,
      () => {
        if (typeof props.withConfirmFn !== 'undefined') {
          withConfirm.value = props.withConfirmFn(bulkForm.form, bulkForm.validation)
        }
      },
      {
        deep: true
      }
    )

    return {
      ...bulkForm,
      t,
      onCancel,
      route,
      isValid,
      computedColumns,
      previewPagination,
      paginatedRows,
      FieldType,
      withConfirm
    }
  }
})
</script>
